export default {
  pt: {
    buttonCandidature: 'Candidatar-se',
    people1: {
      title: `A aposta da Ucall na minha formação contínua foi decisiva para o meu desempenho.`,
      name: `Allen Kaputu, Directora de Serviços Gerais, Logística e Património`,
      decription: `<p>Entrei na Ucall em 2008, com pouca experiência de trabalho e sem qualquer experiência em cargos de liderança.</p>
       <p>Inicialmente como assistente, depois como supervisora, passei para gestora da Academia e em 2015, integrei a direcção da Ucall, como Gestora de Melhoria Contínua.
      Actualmente assumo a função de directora dos Serviços Gerais, Logística e Património. Esta progressão deve-se à combinação de dois factores: ao meu empenho e dedicação e à aposta da Ucall na minha formação, ao longo dos anos.</p>
      <p> Na Ucall, todos “vestimos a camisola e trabalhamos em equipa e só assim é possível estarmos à altura dos desafios a que nos propomos diariamente.</p>`
    },
    people2: {
      title: 'De assistente de Contact Center para o desafio de gerir 740 pessoas, como Gestor de Contas, tem sido um percurso incrível.',
      name: 'Carlos Aguiar, Gestor de Contas da Unitel',
      decription: `Para uma pessoa fechada como eu, no início, ter de lidar com diferentes tipos de clientes foi uma prova desafiante mas superada com sucesso.</p>
      <p> Aprendi muito, ganhei experiência, amadureci e contei com a ajuda dos meus superiores, que me deram as competências técnicas e comportamentais para desempenhar a função que assumo hoje. Mas nada disto teria sido possível sem o meu esforço e dedicação. Conciliar os níveis de motivação das equipas com quem trabalho directa ou indirectamente, proporcionando o seu crescimento profissional, com os objectivos organizacionais de prestar um serviço de excelência  à UNITEL, enquanto nosso parceiro estratégico, tem sido estimulante e, sobretudo, recompensador.</p>`
    },
    people4: {
      title: 'Sempre tive o sonho de trabalhar como técnico de informática e estou a um passo de o realizar.',
      name: 'Januário Francisco, Coordenador de Helpdesk',
      decription: `<p>A minha jornada na Ucall começou em 2014 como assistente de Contact Center, na operação Unitel.</p>
      <p> No ano seguinte, abracei um novo desafio na área de Logística e Património como assistente de manutenção. Além de aprender muito, ganhei maturidade profissional.</p>
      <p> Eu estava certo que na Ucall é possível fazer carreira, pois agora estou a ter a oportunidade de estagiar na área em que sempre sonhei.</p>`
    },
    people5: {
      title: `Não desisti até conseguir fazer parte desta grande equipa!`,
      name: 'Zeyna Mohamed, Assistente de Contact Center',
      decription: `Sempre quis trabalhar na Ucall e, quando fiz 18 anos, não parei até ser admitida. Fiz candidaturas  espontâneas, enviei o meu CV por email e, por fim,  gravei o vídeo. Na realidade, foram muito os vídeos que gravei, mas persisti e não desisti!</p>
      <p>Na Ucall, embora demonstremos sempre disponibilidade para ajudar os nossos clientes, lidar com diversas personalidades, nem sempre é fácil.
      Mas é esta experiência que faz com que todos os dias eu aprenda algo novo e me torne cada vez melhor profissional, preparada  para crescer pessoal e profissionalmente.</p>`
    },
    people7: {
      title: 'Nós somos uma Empresa-Escola e eu revejo-me inteiramente neste conceito.',
      name: `Eurídce Martins, Gestor de Contas`,
      decription: `<p>Estava a estudar na faculdade, quando tive a oportunidade de começar a trabalhar como assistente de Call Center no turno da noite.</p>
      <p> Além da formação nas diversas áreas de atendimento,  foi me dada a oportunidade de participar em formações técnicas e comportamentais que me prepararam para o acréscimo de responsabilidades que tenho vindo a assumir.</p>
      <p> Hoje, como gestor operacional, estou num patamar em que a responsabilidade é muita, pois além do desafio diário inerente à gestão de uma operação, somos líderes de outros líderes. O nosso acompanhamento contínuo às equipas, a partilha do nosso conhecimento e das nossas experiências é que os preparará para os desafios de amanhã.</p>`
    },
    people3: {
      title: 'A Ucall é um desafio constante e obriga-nos a reinventarmo-nos todos os dias.',
      name: `Carlos Amaral, Director de Desenvolvimento de Negócio e Business Solutions`,
      decription: `<p>Entrei para a Empresa mais feliz de Angola em 2009, integrando a equipa da UNITEL.
      A mudança para a Operação da TAAG, foi verdadeiramente uma das melhores coisas que aconteceu-me na vida, pela oportunidade que tive de provar a minha competência e mostrar-me apto para assumir as funções primeiro de Supervisor, depois de Coordenador Operacional e mais tarde, de Gestor Operacional de Contact Center.</p>
      <p>O caminho trilhado nas operações contribui para a minha afirmação como profissional do quadro de gestão da UCALL. A confiança depositada num profissional jovem como eu, traduziu-se na oportunidade de gerir, de forma autónoma, a Operação da TAAG e no convite para fazer um estágio profissional na Europa, em Portugal.</p>
      <p>Hoje assumo a função de Director Adjunto de Negócio, os desafios que enfrento são imensos mas o meu empenho, dedicação e resiliência torna possível aprender, crescer e superar!</p><br>`
    },
    people8: {
      title: 'As operações são a minha paixão.',
      name: `Mário Tazi, Director de Operações`,
      decription: ` <p>Estou na Ucall desde o início e, ao longo dos anos, fui progredindo passando por todas as funções até chegar à que desempenho actualmente, a de Director de Negócios.</p>
                    <p>Desde a gestão operacional, passando pela gestão das pessoas, até ao dinamismo e evolução deste sector, várias têm sido as experiências que acrescentaram valor e foram decisivas para o sucesso do meu percurso.</p>
                    <p>Na Ucall, todos podem chegar mais longe. Basta ter brio e dedicação profissional, tirar o máximo partido das inúmeras formações e agarrar afincadamente cada uma das oportunidades que surgem.</p>`
    },
    people6: {
      title: 'A UCALL é a minha casa. Deu-me as bases para ser quem sou hoje.',
      name: `Yara Júnior, Gestora de Projectos de Desenvolvimento de Capital Humano`,
      decription: `<p>Lembro-me do quão emocionante  foi o meu primeiro dia de trabalho na Ucall aos 19 anos. Não sabia absolutamente nada,  foi o meu primeiro emprego mas esta situação, não foi de maneira nenhuma uma barreira.</p>
     <p>A Ucall identificou o potencial, acreditou e apostou em mim, no meu crescimento e desenvolvimento pessoal e profissional, dando-me várias oportunidades ao longo destes anos.</p>
     <p>Hoje, na função que ocupo, tenho a responsabilidade de formar os outros, transmitindo-lhes os valores e a cultura organizacional da Ucall e faço-o com muito orgulho e amor à camisola.</p>`
    }
  },
  en: {
    buttonCandidature: 'Apply',
    people1: {
      title: `Ucall's bet on my continuing training was decisive for my performance.`,
      name: `Allen Kaputu, Director of General Services, Logistics and Property`,
      decription: `<p>“I joined Ucall in 2008 with little work experience and without any experience in leadership positions.</p>
        <p>Initially I was an assistant, then a supervisor, after I went to the position of manager of the Academy, and in 2015 I joined Ucall's management as Continuous Improvement Manager. I am currently the Director of General Services, Logistics and Property. This progression is due to two factors: my personal commitment and dedication, and Ucall's commitment to my training over the years.</p>
      <p> At Ucall, we are all committed with our labour and team work, and only then can we live up to the challenges we set up on a daily basis.</p>`
    },
    people2: {
      title: 'From Contact Center Assistant to the challenge of managing 740 people, as an Account Manager, has been an amazing route.',
      name: 'Carlos Aguiar, Unitel Account Manager.',
      decription: `For a closed person like me at first, having to deal with different types of customers was a challenging task but I successfully overcame.</p>
      <p> I learned a lot, gained experience, became mature and counted on the help of my superiors, who gave me the technical and behavioural skills to perform the position I assume today. But none of this would have been possible without my effort and dedication. Reconciling the levels of motivation of the teams that I directly or indirectly work with, providing their professional growth, with the organizational target to provide an excellent service to our client UNITEL, as our strategic partner, has been stimulating and, above all, rewarding.</p>`
    },
    people4: {
      title: 'I have always had the dream of working as a computer technician and I am one step away from realizing it.',
      name: 'Januário Francisco, Helpdesk Coordinator',
      decription: `<p>My journey at Ucall began in 2014 as a Contact Center Assistant at Unitel operation.</p>
      <p> The following year, I embraced a new challenge in Logistics and Property area, as a Maintenance Assistant. In addition to learning a lot, I gained professional maturity.</p>
      <p> I am sure that at Ucall it is possible to make a career, because now I am having the opportunity to intern in the area that I've always dreamed of.</p>`
    },
    people5: {
      title: `I didn't give up until I could be part of this great team!`,
      name: 'Zeyna Mohamed, Contact Center Assistant',
      decription: `I always wanted to work at Ucall, and when I turned 18, I didn't stop until I was admitted. I made spontaneous applications, emailed my CV, and finally recorded a video. In fact, there were a lot of videos I recorded, but I persisted, and I didn't give up!</p>
      <p>At Ucall, while we always show willingness to help our customers, dealing with diverse personalities, is not always easy. However, this experience is what makes me learn something new every day and become an increasingly better professional, prepared to grow personally and professionally.</p>`
    },
    people7: {
      title: 'We are a Company-School and I see myself entirely in this concept.',
      name: `Eurídce Martins, Account Manager`,
      decription: `<p>I was studying in college when I had the opportunity to start working the night shift as a Call Center assistant.</p>
      <p> In addition to training in the various areas of customer service, I was given the opportunity to participate in technical and behavioural training that prepared me for the added responsibilities that I have been taking on.</p>
      <p> Today, as an Operational Manager, I am at a level with a lot of responsibility, because in addition to the daily challenge inherent in the management of an operation, we are leaders of other leaders. Our continued monitoring of teams, sharing our knowledge and experiences is what will prepare them for tomorrow's challenges.</p>`
    },
    people3: {
      title: 'Ucall is a constant challenge and forces us to reinvent ourselves every day.',
      name: `Carlos Amaral, Director of Business Development and Business Solutions`,
      decription: `<p>I joined the happiest company in Angola in 2009, joining the UNITEL operation team. The move to TAAG operation was truly one of the best things that happened to me in life, for the opportunity I had to prove my competence and show myself able to assume the functions, first as a Supervisor, then as an Operational Coordinator, and later as Contact Center Operational Manager.</p>
      <p>The path trodden in operations contributes to my statement as a professional in Ucall's management board. The trust placed in a young professional like me has resulted in the opportunity to manage, autonomously, TAAG Operation and the invitation to do a professional internship in Europe, Portugal.</p>
      <p>Today I assume the position of Deputy Business Director. The challenges I face are immense, but my commitment, dedication and resilience make it possible to learn, grow and overcome!</p><br>`
    },
    people8: {
      title: 'Operations are my passion.',
      name: `Mário Tazi, Director of operations`,
      decription: ` <p>I've been at Ucall from the beginning and over these years, I've been making progress through all my duties, leading me to the position of Business Director.</p>
                    <p>From operational management, through people management, to the dynamism and evolution of this sector, several experiences have added value and have been decisive for the success of my journey.</p>
                    <p>At Ucall, everyone can get further. We just need to have professional dedication, make the most of the countless training and seize each of the opportunities that arise.</p>`
    },
    people6: {
      title: 'UCALL is my home. It gave me the foundation to be who I am today.',
      name: `Yara Júnior, Manager of Human Capital Development Projects`,
      decription: `<p>I remember how exciting my first day at work at Ucall was. I was 19! I knew absolutely nothing, it was my first job, but this situation was by no means a barrier.</p>
      <p>Ucall identified the potential, believed and bet on me, on my growth and personal and professional development, giving me several opportunities over the years.</p>
      <p>Today, in the position I occupy, I have the responsibility to train others, transmitting Ucall's values and organizational culture to them and I do so with great pride and love.</p>`
    }
  }
}
