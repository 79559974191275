import App from '@/data/App'
import Home from '@/data/Home'
import Solutions from '@/data/Solutions'
import Cases from '@/data/Cases'
import People from '@/data/People'
import Contacts from '@/data/Contacts'
import Modal from '@/data/Modal'
import About from '@/data/About'

export default {
  ...App.pt,
  ...Home.pt,
  ...Solutions.pt,
  ...Cases.pt,
  ...People.pt,
  ...Contacts.pt,
  ...Modal.pt,
  ...About.pt
}
