export default {
  pt: {
    case1: {
      title: 'Lorem ipsum dolor',
      intro: 'Ipsum quo minima accusantium beatae placeat minus',
      decription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore mollitia labore, explicabo ducimus delectus iusto suscipit? Sit blanditiis ipsum, accusantium quasi eos vel non nam repellat asperiores cumque dolorum recusandae.'
    },
    case2: {
      title: 'Lorem ipsum Futre',
      intro: 'Ipsum quo minima accusantium beatae placeat minus',
      decription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore mollitia labore, explicabo ducimus delectus iusto suscipit? Sit blanditiis ipsum, accusantium quasi eos vel non nam repellat asperiores cumque dolorum recusandae.'
    },
    case3: {
      title: 'Lorem ipsum dolor2',
      intro: 'Ipsum quo minima accusantium beatae placeat minus',
      decription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore mollitia labore, explicabo ducimus delectus iusto suscipit? Sit blanditiis ipsum, accusantium quasi eos vel non nam repellat asperiores cumque dolorum recusandae.'
    },
    case4: {
      title: 'Lorem ipsum carlos',
      intro: 'Ipsum quo minima accusantium beatae placeat minus',
      decription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore mollitia labore, explicabo ducimus delectus iusto suscipit? Sit blanditiis ipsum, accusantium quasi eos vel non nam repellat asperiores cumque dolorum recusandae.'
    },
    case5: {
      title: 'Lorem ipsum test',
      intro: 'Ipsum quo minima accusantium beatae placeat minus',
      decription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore mollitia labore, explicabo ducimus delectus iusto suscipit? Sit blanditiis ipsum, accusantium quasi eos vel non nam repellat asperiores cumque dolorum recusandae.'
    }
  },
  en: {
    case1: {
      title: 'Lorem ipsum dolor',
      intro: 'Ipsum quo minima accusantium beatae placeat minus',
      decription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore mollitia labore, explicabo ducimus delectus iusto suscipit? Sit blanditiis ipsum, accusantium quasi eos vel non nam repellat asperiores cumque dolorum recusandae.'
    },
    case2: {
      title: 'Lorem ipsum dolor',
      intro: 'Ipsum quo minima accusantium beatae placeat minus',
      decription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore mollitia labore, explicabo ducimus delectus iusto suscipit? Sit blanditiis ipsum, accusantium quasi eos vel non nam repellat asperiores cumque dolorum recusandae.'
    },
    case3: {
      title: 'Lorem ipsum dolor',
      intro: 'Ipsum quo minima accusantium beatae placeat minus',
      decription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore mollitia labore, explicabo ducimus delectus iusto suscipit? Sit blanditiis ipsum, accusantium quasi eos vel non nam repellat asperiores cumque dolorum recusandae.'
    },
    case4: {
      title: 'Lorem ipsum dolor',
      intro: 'Ipsum quo minima accusantium beatae placeat minus',
      decription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore mollitia labore, explicabo ducimus delectus iusto suscipit? Sit blanditiis ipsum, accusantium quasi eos vel non nam repellat asperiores cumque dolorum recusandae.'
    },
    case5: {
      title: 'Lorem ipsum dolor',
      intro: 'Ipsum quo minima accusantium beatae placeat minus',
      decription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore mollitia labore, explicabo ducimus delectus iusto suscipit? Sit blanditiis ipsum, accusantium quasi eos vel non nam repellat asperiores cumque dolorum recusandae.'
    }
  }
}
