<template>
  <div id="app">
    <router-view />
    <transition v-on:enter="enter" appear>
      <div class="modal" v-if="showModal">
        <div class="mobile-fullscreen">
          <div class="header">
            <p>{{ $t("app.fullscreenText") }}</p>
          </div>
          <div class="body--buttons">
            <button class="btn" @click="toogleModal">
              {{ $t("app.cancelButton") }}
            </button>
            <button class="full-screen btn" @click="fullscreen">
              {{ $t("app.activateFullScreenButton") }}
            </button>
          </div>
        </div>
      </div>
    </transition>
    <div class="footer-menu">
      <menu-chat
        @open-website="toggleIframe"
        @open-whatsapp="openWhatsApp"
        @open-messenger="toggleMessenger"
      />
    </div>
    <!-- frame chat -->
    <div class="frame-wrapper">
      <!-- <iframe src="https://omnichannels.ucall.co.ao/ucall/form_chat_teste.html"></iframe> -->
    </div>
  </div>
</template>
<script>
import 'gsap/TweenMax'
import ScreenFull from 'screenfull'
import TweenLite from 'gsap/TweenLite'
import MenuChat from './components/Menu/MenuChat'
import axios from 'axios'
export default {
  name: 'App',
  components: {
    MenuChat
  },
  watch: {
    $route: function () {
      if (['Home'].includes(this.$route.name)) {
        try {
          this.$skrollr.refresh()
        } catch {
          // eslint-disable-next-line
          this.$skrollr = skrollr.init();
        }
      } else {
        this.$skrollr.destroy()
      }
    }
  },
  data () {
    return {
      showModal: false,
      showIframe: false,
      config: {}
    }
  },
  mounted () {
    this.get()
    this.$i18n.locale = navigator.languages[0].split('-')[0]
    this.resize()
  },
  methods: {
    async get () {
      await axios
        .get(`${process.env.VUE_APP_SQUIDEX_URL}/api/content/${process.env.VUE_APP_SQUIDEX_APP_NAME}/app-config`, {
          params: {
            $orderby: 'created desc',
            $limit: 8
          },
          headers: {
            'X-Flatten': true
          }
        })
        .then((resp) => {
          const data = resp.data.items[0]
          if (data) {
            this.config = data.data
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error)
        })
    },
    fullscreen () {
      this.canShowModal = false
      if (ScreenFull.isEnabled) {
        ScreenFull.request()
      }

      this.toogleModal()
    },
    toogleModal () {
      window.FB.CustomerChat.hideDialog()
      this.showModal = !this.showModal
    },
    enter (el, done) {
      TweenLite.to(document.querySelector('.mobile-fullscreen'), 1, {
        top: '20vh'
      })
      done()
    },
    resize () {
      const callModal = () => {
        if (screen.width < 960) this.toogleModal()
        else this.showModal = false
      }
      // this.resizeInstance = window.addEventListener('resize', callModal)
      if (!navigator.userAgent.includes('iPhone')) callModal()
    },
    toggleIframe () {
      window.FB.CustomerChat.hide()
      if (window.xgen) {
        window.xgen.maximizeEmbed()
      }
    },
    toggleMessenger () {
      if (window.xgen) {
        window.xgen.closeEmbed()
      }
      window.FB.CustomerChat.showDialog()
    },
    openWhatsApp () {
      window.open('https://api.whatsapp.com/send?phone=' + this.config.whatsapp, '__blank')
    }
  }
}
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
@import url('https://fonts.googleapis.com/css?family=Lato:300&display=swap');
* {
  padding: 0;
  margin: 0;
  font-family: "Roboto", sans-serif;
  outline: none;
}
.color-white{
  color: #ffffff!important;
}
.footer-menu {
  position: fixed;
  bottom: 0;
  right: 12px;
  display: block;
}

.frame-wrapper {
  // display: none;
  vertical-align: bottom;
  width: 100vw;
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: #fff;

  iframe {
    height: 100%;
    width: 100%;
    border: 0;
  }
}

#embed-header-minimized .embed-call-window.embed-maximize{
  display: none !important;
}

.footer-menu {
  position: fixed;
  bottom: 0;
  right: 12px;
  display: block;
}

.frame-wrapper {
  // display: none;
  vertical-align: bottom;
  width: 100vw;
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: #fff;

  iframe {
    height: 100%;
    width: 100%;
    border: 0;
  }
}

#embed-header-minimized .embed-call-window.embed-maximize{
  display: none !important;
}

.no-margin {
  margin: 0 !important;
  padding: 0;
}

.a {
  color: #fff !important;
  text-transform: lowercase;
  text-decoration: none !important;
}

.fb_dialog {
  display: none !important;
}

iframe.fb_customer_chat_bounce_in_v2 {
  bottom: 39pt !important;
  right: 80pt !important;
}

.btn_menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  .tree-lines-first,
  .tree-lines-middle,
  .tree-lines-last {
    border: 1px solid #fff;
    width: 20px;
    margin-top: 6px;
    border-radius: 12px;
  }

  .tree-lines-first,
  .tree-lines-last {
    width: 30px;
  }
}

button {
  background: transparent;
  border: 0;
}

:root {
  --swiper-theme-color: #DC1C2E;
}
.swiper-pagination-bullet {
  width: 35px;
  height: 8px;
  display: inline-block;
  border-radius: 20px;
  background: transparent;
  border: 1px solid #DC1C2E;
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background: #DC1C2E;
}
.swiper-container {
  box-sizing: border-box;
  padding-bottom: 20px;
  color: #fff;
  margin: 0 20px;
}
@media (min-width: 768px) {
  .swiper-pagination {
    display: block;
  }
  .swiper-container {
    padding-bottom: 50px;
  }
  .footer-menu {
    display: none;
  }
}
.swiper-slide {
  width: 60%;
}

.Nossos::before,
.Nossos {
  color: #1ba2a6 !important;
}
.Nosso::before,
.Nosso {
  color: #dc454c !important;
}
.Sobre::before,
.Sobre {
  color: #c42462 !important;
}
.UContact::before,
.UContact {
  color: #d83541 !important;
}
.UDigital::before,
.UDigital {
  color: #eb7f54 !important;
}
.UKnow::before,
.UKnow {
  color: #df5b66 !important;
}
.UTalent::before,
.UTalent {
  color: #e0112c !important;
}
.UMember::before,
.UMember {
  color: #c7522a !important;
}
.vb > .vb-dragger {
  z-index: 5;
  width: 12px;
  right: 0;
}

.vb > .vb-dragger > .vb-dragger-styler {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotate3d(0, 0, 0, 0);
  transform: rotate3d(0, 0, 0, 0);
  -webkit-transition: background-color 100ms ease-out, margin 100ms ease-out,
    height 100ms ease-out;
  transition: background-color 100ms ease-out, margin 100ms ease-out,
    height 100ms ease-out;
  background-color: rgba(216, 53, 65, 0.1);
  margin: 5px 5px 5px 0;
  border-radius: 20px;
  height: calc(100% - 10px);
  display: block;
}

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(216, 53, 65, 0.3);
}

.vb > .vb-dragger:hover > .vb-dragger-styler {
  background-color: rgba(216, 53, 65, 0.3);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(216, 53, 65, 0.3);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(216, 53, 65, 0.3);
}
</style>
<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: visibleFill;
  color: gray;
  z-index: 9999;
  text-align: center;
}
.mobile-fullscreen {
  background-color: #fff;
  position: absolute;
  top: -100vh;
  height: 20vh;
  width: 80vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 12px;
  box-shadow: 1px 0px 9px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  padding: 16px 16px;

  .body--buttons {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;
    .btn {
      background-color: rgba($color: #9999, $alpha: 0.6);
      font-size: 14px;
      border: 0;
      margin-right: 5px;
      padding: 10px;
      border-radius: 10px;
      color: #fff;
      cursor: pointer;
    }

    .full-screen {
      background-color: rgba($color: #9999, $alpha: 1);
    }
    .swiper-pagination {
      display: none;
    }
  }
}

</style>
