import Vue from 'vue'
import VueAnalytics from 'vue-ua'
import VueRouter from 'vue-router'
import DefaultLayout from '@/layouts/Default.vue'

Vue.use(VueRouter)
const nameMenu = 'Sobre Nós'
const routes = [
  {
    path: '/',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/pages/Home.vue'),
        meta: { analytics: 'Home' }
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import('@/pages/Contacts.vue'),
        meta: { analytics: 'Contacts' }
      },
      {
        path: 'about',
        redirect: { name: 'About', params: { about: nameMenu.toLowerCase().replace(/\s+/g, '-'), menu: nameMenu } },
        meta: { analytics: 'About' }
      },
      {
        path: 'about/:about'.toLowerCase(),
        name: 'About',
        component: () => import('@/pages/About.vue'),
        meta: { analytics: 'About' }
      },
      /* {
        path: 'cases',
        component: () => import('@/pages/Cases.vue'),
        children: [
          {
            path: '',
            name: 'Cases',
            component: () => import('@/components/People/Index'),
            meta: { analytics: 'Cases' }
          },
          {
            path: ':id',
            name: 'Case',
            component: () => import('@/components/People/CardMaximized/Index'),
            meta: { analytics: 'Case' }
          }
        ]
      },
      */
      {
        path: 'solutions',
        redirect: { name: 'Solutions', params: { solution: 'UContact' } },
        meta: { analytics: 'Solutions' }
      },
      {
        path: 'solutions/:solution'.toLowerCase(),
        name: 'Solutions',
        component: () => import('@/pages/Solutions.vue'),
        meta: { analytics: 'Solutions' }
      },
      {
        path: 'people',
        name: 'People',
        component: () => import('@/pages/People.vue'),
        meta: { analytics: 'People' }
      },
      {
        path: 'responsibility',
        name: 'Responsibility',
        component: () => import('@/pages/Responsibility.vue'),
        meta: { analytics: 'Responsibility' }
      },
      {
        path: 'responsibility/View',
        name: 'ResponsibilityView',
        component: () => import('@/components/Responsibility/ItemView.vue'),
        meta: { analytics: 'Responsibility' }
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/pages/Login.vue')
      },
      {
        path: 'recover',
        name: 'Recover',
        component: () => import('@/pages/Recover.vue')
      },
      {
        path: '*',
        name: 'not_found',
        redirect: { name: 'Home' }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'active-route',
  base: process.env.BASE_URL,
  routes
})

Vue.use(VueAnalytics, {
  // [Required] The name of your app as specified in Google Analytics.
  appName: 'Ucall Website',
  // [Required] The version of your app.
  appVersion: '1.0.0',
  // [Required] Your Google Analytics tracking ID.
  trackingId: process.env.VUE_GOOGLE_ANALYTICS || 'UA-155367306-1',
  trackPage: true,
  // If you're using vue-router, pass the router instance here.
  vueRouter: router
})

export default router
