import Vue from 'vue'
import './boot/vuebar'
import './boot/vue-skrollr'
import './boot/vue-meta'
import './boot/vue-swiper'
import './boot/vue-notification'
import './boot/vue-validate'
import { i18n } from './boot/vue-i18n'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
Vue.prototype.$eventHub = new Vue()

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
