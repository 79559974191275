import Vue from 'vue'
import izItoast from 'izitoast'
import 'izitoast/dist/css/iziToast.min.css'

function toast ({ title, message, type, timeout = 6000 }) {
  return izItoast[type]({
    message,
    title,
    timeout,
    position: 'topCenter'
  })
}

Vue.prototype.$notification = toast
