export default {
  pt: {
    contact: {
      txtContact: 'CONTACTOS',
      firstLocalization: 'CONDOMÍNIO ALPHA ESCRITÓRIOS',
      secondLocalization: 'RUA DO MAT - TALATONA',
      thirdLocalization: 'LUANDA- ANGOLA',
      Number: '+244 923 190 190',
      Email: 'info@ucall.co.ao',
      inputs: {
        placeholderName: 'Nome',
        placeholderMsg: 'Mensagem',
        buttonSend: 'Enviar'
      },
      description: 'Página de concactos',
      keywords: 'contactos, telefone ucall, email ucall',
      // ucallPT: `
      // Torre 1 Amoreiras 3° andar Salas 4/5
      // 1070-101
      // Lisboa - Portugal
      // <a class="a" href="mailto:info@ucall.pt">info@ucall.pt</a>
      // `,
      contactSucceeded: 'Contacto enviado com sucesso!',
      contactNotSucceeded: 'Não foi possível enviar contacto!'
    }
  },
  en: {
    contact: {
      txtContact: 'CONTACTS',
      firstLocalization: 'CONDOMÍNIO ALPHA ESCRITÓRIOS',
      secondLocalization: 'RUA DO MAT - TALATONA',
      thirdLocalization: 'LUANDA- ANGOLA',
      Number: '+244 923 190 190',
      Email: 'info@ucall.co.ao',
      inputs: {
        placeholderName: 'Name',
        placeholderMsg: 'Message',
        buttonSend: 'Send'
      },
      description: 'Contacts page',
      keywords: 'contacts, ucall telephone, ucall email',
      // ucallPT: `
      // Torre 1 Amoreiras 3° andar Salas 4/5
      // 1070-101
      // Lisboa - Portugal
      // <a class="a" href="mailto:info@ucall.pt">info@ucall.pt</a>
      // `,
      contactSucceeded: 'Contact was sended as successfully!',
      contactNotSucceeded: 'Can not send contact, try again later!'
    }
  }
}
