import Vue from 'vue'
import Vuex from 'vuex'
import Menu from './modules/menu'
import Login from './modules/auth/login'
import Solutions from './modules/solutions'
import About from './modules/about'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showChat: true,
    appConfig: {}
  },
  getters: {
    isShowChat (state) {
      return state.showChat
    },
    appConfig (state) {
      return state.appConfig
    }
  },
  mutations: {
    SET_CHAT_STATE: function (state, payload) {
      state.showChat = payload
    },
    SET_APP_CONFIG: function (state, payload) {
      state.appConfig = payload
    }
  },
  actions: {
    async setAppConfig ({ commit }, data) {
      await axios
        .get(`${process.env.VUE_APP_SQUIDEX_URL}/api/content/${process.env.VUE_APP_SQUIDEX_APP_NAME}/app-config`, {
          headers: {
            'X-Resolve-Urls': 'logo,image',
            'X-Languages': data.lang,
            'X-Flatten': true
          }
        })
        .then((resp) => {
          commit('SET_APP_CONFIG', { ...{ id: resp.data.items[0].id }, ...resp.data.items[0].data })
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error)
        })
    }
  },
  modules: {
    login: Login,
    menu: Menu,
    solutions: Solutions,
    about: About
  }
})
