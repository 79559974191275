<template>
  <div class="menuTexts">
    <ul id="navMenu">
      <li>
        <router-link :to="menuTexts[3].path">
          {{ $t(menuTexts[3].text) }}
        </router-link>
      </li>
      <li>
        <router-link :to="menuTexts[0].path">
          {{ $t(menuTexts[0].text) }}
          <sub>
            <span>
              {{ $t("app.forYourBusiness") }}
            </span>
          </sub>
        </router-link>
      </li>
      <li
        :class="{ 'people-link-border': expandButton }"
        @click="toggleMenu"
        class="people-link-bord"
      >
        <a>
          {{ $t("app.menu.people") }}
        </a>
      </li>
      <template v-if="expandButton">
        <li class="expanded-link">
          <router-link to="/people">
            {{ $t("app.menu.heroes") }}
          </router-link>
        </li>
        <li class="expanded-link">
          <a href="https://recrutamento.ucall.co.ao/" target="_blank">
            <!-- <a href="https://recrutamento-hml.ucall.co.ao/" target="_blank"> -->
            {{ $t("app.menu.jobs") }}
          </a>
        </li>
      </template>
      <li>
        <router-link :to="menuTexts[2].path">
          {{ $t(menuTexts[2].text) }}
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'MenuText',
  data () {
    return {
      expandButton: false
    }
  },
  props: {
    menuTexts: {
      required: true,
      type: Array
    }
  },
  methods: {
    toggleMenu () {
      this.expandButton = !this.expandButton
    }
  }
}
</script>
<style lang="scss" scoped>
/* THEME COLOR VARIABLES */
$theme_cases_color: #fec054;
$theme_shared_color: #3c3c3c;
$theme_solutions_color: #e18697;
$theme_people_color: #d83541;
$theme_primary_color: #fff;
$theme_active_route_width: 80%;

@mixin activeRouteStyle($theme_color, $theme_color_text) {
  background-color: $theme_color;
  color: $theme_color_text !important;
  width: $theme_active_route_width !important;
  padding: 16px !important;

  @media (min-width: 768px) {
    width: 50% !important;
  }
}

a sub {
  display: block;
}

a {
  margin-left: 5px;
  transition: color, padding 0.26s ease-in;
}
.expanded-link {
  a {
    font-size: 20px !important;
    margin-left: 0px;
    font-weight: 400 !important;
  }
}
.people-link-border {
  padding-block-end: 15px;
  border-width: 30px;
  border-bottom: 1px solid rgb(204, 187, 187);
}

.ThemeContacts li {
  width: 97% !important;
  a {
    &.active-route {
      @include activeRouteStyle($theme_primary_color, $theme_people_color);
    }
  }
}
.ThemeSolutions li a {
  color: $theme_primary_color !important;

  &.active-route {
    background-color: $theme_primary_color;
    color: $theme_solutions_color !important;
    width: 100% !important;
    padding: 16px !important;

    span {
      color: inherit;
    }
  }
}

.ThemeCases li a.active-route {
  @include activeRouteStyle($theme_primary_color, $theme_cases_color);
}

.ThemeCases #navMenu li a,
.ThemeContacts #navMenu li a,
.ThemeHome #navMenu li a {
  color: $theme_shared_color;
  span {
    color: inherit;
  }
}

#navMenu {
  width: 100%;
  li {
    margin: 15px 0;
    list-style: none;
    a {
      text-decoration: none;
      text-transform: uppercase;
      font-size: 30px;
      font-weight: bolder;
      display: flex;
      flex-direction: column;
      width: 100vw;
      align-items: flex-start;
      padding: 0 20px;
      cursor: pointer;
      box-sizing: border-box;

      span {
        top: -60px;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 100;
      }
    }
  }
}

.menuTexts {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: auto;
}

@media (min-width: 768px) {
  $theme_active_route_width: 50%;

  a sub {
    display: unset;
  }
  .mobile-show {
    display: none;
  }
  #navMenu {
    margin-left: 50px;
    li {
      margin: 25px 0;

      a {
        flex-direction: row;
        font-size: 48px;
        span {
          font-size: 30px;
          margin-left: 35px;
        }
      }
    }
  }

  .menuTexts {
    margin-top: 25px;
    height: 100%;
    align-items: flex-start;
  }

  .expanded-link {
    a {
      font-size: 30px !important;
      margin-left: 40px;
      font-weight: 400 !important;
    }
  }
}
</style>
