export default {
  pt: {
    home_slide1: {
      title: '<h1 class="card--title">Líder na <br /><span>Experiência de Cliente e Contact Center</span><br />em Angola</h1>',
      subtitle: 'Com mais de 12 anos de experiência em Contact Center, somos o parceiro certo para desenhar e implementar soluções multicanal de atendimento ao cliente e soluções de vendas remotas com foco nos resultados, na qualidade e na simplicidade.'
    },
    home_content_slide2: {
      experience: '<h1 class="card--cite"><span>37 Milhões</span><br /> de interacções</h1>',
      body_description:
        'A experiência na gestão e operacionalização de grandes volumes de interacções nos nossos Contact Centers, permite-nos agregar Know How no desenho de soluções adequadas a cada negócio, e soluções de reporting e análise de dados cruciais para a tomada de decisão. As nossas equipas estão a operar quer em sistema presencial, quer em Teletrabalho, com acesso ao melhor acompanhamento, tecnologia e sistemas de segurança.'
    },
    home_content_slide3: {
      experience: '<h2 class="card--title-normal">O PARCEIRO <br/>  DOS PRINCIPAIS <br/>  <span>PLAYERS</span><br/>  DO MERCADO <br/>  ANGOLANO</h2>',
      body_description:
        'Temos vindo a estabelecer parcerias duradouras com as empresas de referência dos vários sectores de actividade, tornando-nos no seu parceiro estratégico: Telco & Media; Banca & Seguros; Logística & Transportes; Aviação; Saúde; Distribuição e Estado.'
    },
    home_content_slide4: {
      experience: '<h2 class="card--cite">VANGUARDA NA <br/> <span>TECNOLOGIA <br/>& INOVAÇÃO</span> </h2>',
      body_description:
        'As nossas soluções de Contact Center estão assentes em tecnologia de última geração. Entregamos soluções inovadoras que aceleram a transformação digital e melhoram a experiência dos seus clientes.'
    },
    home_content_slide6: {
      experience: '<h2 class="card--subtitle big">#EMPRESA <br/> MAIS FELIZ <br/>DE ANGOLA</h2>',
      body_description:
        'Somos movidos a energia positiva. Estamos comprometidos com o desenvolvimento do potencial de cada uma das nossas pessoas e juntos abraçamos causas que impactam a comunidade que nos rodeia. A felicidade mora aqui!'
    }
  },
  en: {
    home_slide1: {
      title: '<h1 class="card--title">Angola <br /><span>Customer Experience and Contact Center</span><br /> leader</h1>',
      subtitle: 'With more than 12 years of Contact Center experience, we are the right partner to design and implement multichannel customer service and remote sales solutions with focus on results, quality and simplicity.'
    },
    home_content_slide2: {
      experience: '<h1 class="card--cite"><span>37 Million</span><br />of INTERACTIONS<br /></h1>',
      body_description: 'Operational management experience of large interactions volume in our Contact Centers, enable us to add Know How in design of right solutions for each business, reporting and analysis solutions for crucial data for decision-making. Our teams are operating either personnaly or in Telework, with access to the best monitoring, technology and security systems.'
    },
    home_content_slide3: {
      experience: '<h2 class="card--title-normal">THE PARTNER<br/> OF THE MAIN<br/> <span>PLAYERS</span><br/> IN THE ANGOLAN<br/> MARKET</h2>',
      body_description: 'We have been developing lasting partnerships with leading companies in several activity areas, becoming our strategic partner: Telco & Media; Banking & Insurance; Logistics & Transport; Aviation; Health; Distribution and Status.'
    },
    home_content_slide4: {
      experience: '<h2 class="card--cite"> VANGUARD IN <br/><span>TECHNOLOGY  <br/>& INNOVATION</span> </h2>',
      body_description: 'Our Contact Center solutions are based on state-of-the-art technology. We deliver innovative solutions that accelerate digital transformation and improve our customer experience.'
    },
    home_content_slide6: {
      experience: '<h2 class="card--subtitle big">HAPPIEST<br/> COMPANY IN ANGOLA </h2>',
      body_description: 'We are powered by positive energy. We are committed to develop the potential of our people, and together we embrace causes that impact the community around us. Happiness lives here!'
    }
  }
}
