import axios from 'axios'

const url = axios.create({
  baseURL: 'http://intranetapi.ucall.co.ao:9797',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
  // headers: {
  //   ContentType: 'application/json',
  //   'mode': 'cors'
  // }
})

export { url }
