export default {
  namespaced: true,
  state: {
    Wrapper1Visibility: 'ShowWrapper1',
    Wrapper2Visibility: 'HideWrapper2',
    SettedBackgrond: 'bgImgListWrapper'
  },
  getters: {
    currentWrapper1Visibility (state) {
      return state.Wrapper1Visibility
    },
    currentWrapper2Visibility (state) {
      return state.Wrapper2Visibility
    },
    currentSettedBackgrond (state) {
      return state.SettedBackgrond
    }
  },
  mutations: {
    SET_WRAPPER1_VISIBILITY: function (state, visibility) {
      state.Wrapper1Visibility = visibility
    },
    SET_WRAPPER2_VISIBILITY: function (state, visibility) {
      state.Wrapper2Visibility = visibility
    },
    SET_SETTED_BACKGROUND: function (state, visibility) {
      state.SettedBackgrond = visibility
    }
  }
}
