<template>
  <main class="menu_links">
    <div v-for="(link, index) in links" :key="index" class="div_links">
      <a :href="link.url" target="_blank" :style="dynamicStyle" class="socialmedia" rel="noopener noreferrer">
        <i :class="link.icon"></i>
      </a>
    </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
  name: 'MenuLinks',
  data () {
    return {
      links: [
        /* {
          href: 'https://instagram.com/ucall_oficial?igshid=vlrzfi7pggds',
          icon: 'icon-Grupo-262'
        },
        {
          href:
            'https://www.linkedin.com/company/ucall-angola/?originalSubdomain=pt',
          icon: 'icon-Grupo-263'
        },
        {
          href: 'https://www.facebook.com/youcallangola/?',
          icon: 'icon-Grupo-264'
        },
        {
          href: 'https://www.instagram.com/ucall_oficial/',
          icon: 'icon-Grupo-262'
        },
        {
          href: 'https://www.youtube.com/channel/UCJP8XZ8eAS0NxxdI30kprsQ',
          icon: 'icon-youtube'
        } */
      ]
    }
  },
  async mounted () {
    this.get()
  },
  methods: {
    async get () {
      await axios
        .get(`${process.env.VUE_APP_SQUIDEX_URL}/api/content/${process.env.VUE_APP_SQUIDEX_APP_NAME}/socialmedia`, {
          params: {
            $orderby: 'created desc',
            $limit: 8
          },
          headers: {
            'X-Flatten': true
          }
        })
        .then((resp) => {
          let data = []
          resp.data.items.forEach((element) => {
            data.push({ ...{ id: element.id }, ...element.data })
          })
          this.links = data
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error)
        })
    }
  },
  computed: {
    ...mapGetters('menu', ['currentTheme']),
    dynamicStyle () {
      let bg, bgHover, color, colorHover
      if (['ThemePeople', 'ThemeContacts', 'ThemeHome'].includes(this.currentTheme)) {
        bg = '#000'
        color = '#fff'
        bgHover = '#3c3c3c'
        colorHover = '#fff'
      }
      return {
        '--socialmedia-bg': bg,
        '--socialmedia-color': color,
        '--socialmedia-bg-hover': bgHover,
        '--socialmedia-color-hover': colorHover
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.socialmedia {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 1.3em;
  width: 1.3em;
  border-radius: 1.3em;
  background-color: var(--socialmedia-bg);
  font-size: 1.3em;
  &:hover {
    background-color: var(--socialmedia-bg-hover);
    i {
    color: var(--socialmedia-color-hover);
    }
  }
  i {
    color: var(--socialmedia-color);
  }
}
.i-size {
  font-size: 30pt;
  &:hover::before, &:hover .path1::before {
    color: rgba($color: #f0f0f0, $alpha: 0.9);
  }
}
/* .div_links {
  a {
    .path1::before {
      color: #000 !important;
    }
    .i-size::before {
      color: #000 !important;
    }
    &:hover {
      .i-size::before {
      color: #3c3c3c !important;
    }
      .path1::before {
        color: #3c3c3c !important;
      }
    }
  }
} */

a {
  outline: none;
  text-decoration: none;
}

.ThemeSolutions {
  &::before, .path1::before {
    color: #fff;
  }

  .path2:before {
    color: #fff;
  }
}

.ThemeCases {
  .path2::before {
    color: #fec054;
  }
}

.ThemeContacts {
  &::before, .path1::before {
    color: #fff;
  }

  .path2:before {
    color: #fff;
  }
}

.div_links {
  margin: 0 5px;
  cursor: pointer;
}

.menu_links {
  display: flex;
  flex-direction: row;
}

@media(min-width: 960px) {
  .menu_links {
    flex-direction: column;
    position: absolute;
    top: 42%;
    /* left: 33%; */
  }

  .i-size {
    font-size: 20pt;
  }

  .div_links {
    margin-bottom: 5px;
  }
}

</style>
