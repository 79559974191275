export default [
  {
    text: 'app.menu.solutions',
    path: '/solutions'
  },
  /*
  {
    text: 'app.menu.cases',
    path: '/cases'
  },
  */
  {
    text: 'app.menu.people',
    path: '/people'
  },
  {
    text: 'app.menu.contacts',
    path: '/contact'
  },
  {
    text: 'app.menu.about',
    path: '/about'
  }
]
