<template>
  <ul :class="currentTheme" >
    <li>
      <button :class="{'active': 'pt' === $i18n.locale}" @click="changeLang('pt')">PT</button>
    </li>
    <li>
      <button :class="{'active': 'en' === $i18n.locale}" @click="changeLang('en')">EN</button>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Menui18n',
  methods: {
    changeLang (lang) {
      this.$i18n.locale = lang
    }
  },
  computed: {
    ...mapGetters('menu', ['currentTheme'])
  }
}
</script>

<style lang="scss" scoped>
ul {
  margin: 0;
  padding: 0;
  display: flex;
}

li {
  list-style: none;
}

li:nth-child(1) {
  border-right: 1px solid #3c3c3c;
  margin-right: 10px;
  padding-right: 10px;
}

button {
  font-size: 20pt;
  cursor: pointer;
}

@media (min-width: 768px) {
  button {
    font-size: 14pt;
  }
}

.ThemeContacts li button {
  // color: #fff;
    &.active {
    font-weight: bolder;
    color: #fbaa01;
  }
}

.ThemeHome li button, .ThemeSolutions li button {
  &.active {
    font-weight: bolder;
    color: #C42663;
  }
}
</style>
