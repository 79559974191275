// import axios from 'axios'
import { url } from '@/api/base-url'

export default {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    LOGIN: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        url.post('/token', payload)
          .then(({ data, status }) => {
            if (status === 200) {
              window.location = 'http://intranet.ucall.co.ao:8082/'
              resolve(true)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}
