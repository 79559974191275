export default [
  {
    route: 'Home',
    theme: 'ThemeHome'
  },
  {
    route: 'Login',
    theme: 'ThemeHome'
  },
  {
    route: 'contact',
    theme: 'ThemeContacts'
  },
  {
    route: 'People',
    theme: 'ThemePeople'
  },
  {
    route: 'Recruitment',
    theme: 'ThemePeople'
  },
  {
    route: 'Cases',
    theme: 'ThemeCases'
  },
  {
    route: 'About',
    theme: 'ThemeHome'
  },
  {
    route: 'Solutions',
    theme: 'ThemeHome'
  },
  {
    route: 'Responsibility',
    theme: 'ThemeHome'
  }
]
