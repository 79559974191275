<template>
    <div class="img--svg" :style="{width, height}" v-html="content"></div>
</template>
<script>
export default {
  name: 'ImgSVG',
  props: {
    src: {
      type: String,
      required: true
    },
    height: [String],
    width: [String]
  },
  data () {
    return {
      content: null
    }
  },
  created () {
    this.getContent()
  },
  methods: {
    async getContent () {
      if (this.src) {
        fetch(Array.isArray(this.src) ? this.src[0] : this.src)
          .then((response) => {
            this.isSVG =
              response.headers.get('content-type') === 'image/svg+xml'
            return response.text()
          })
          .then((text) => {
            this.content = text
          })
      }
    }
  }
}
</script>
<style>
.img--svg svg {
    width: 100%;
    height: 100%;
}
.custom-brand .img--svg path {
  fill: var(--brand-color)
}
</style>
