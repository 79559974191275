<template>
  <div class="menu--chat">
    <ul class="desktop-only" v-if="this.$store.state.showChat">
      <li :class="{ active: chatSelected === 'WhatsApp' }">
        <button @click="selectChat('whatsApp')"
          @mouseenter="changeSelected('whatsApp')"
          @mouseleave="changeSelected('')"
>
          <app-whatsapp
            :height="26"
            :width="26"
            :color="chatSelected !== 'whatsApp' ? '#fff' : '#06d755'"
          />
        </button>
      </li>
      <li :class="{ active: chatSelected === 'messenger' }">
        <button @click="selectChat('messenger')"
          @mousemove="changeSelected('messenger')"
          @mouseleave="changeSelected('')"
>
          <app-messenger
            :height="26"
            :width="26"
            :color="chatSelected !== 'messenger' ? '#fff' : '#0084ff'"
          />
        </button>
      </li>
      <li :class="{ active: chatSelected === 'website' }">
        <button @click="selectChat('website')"
          @mousemove="changeSelected('website')"
          @mouseleave="changeSelected('')">
          <app-yuca
            :height="26"
            :width="26"
            :color="chatSelected !== 'website' ? '#fff' : '#da6259'"
          />
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'MenuChat',
  components: {
    'app-messenger': () => import('../shared/messenger'),
    'app-whatsapp': () => import('../shared/whatsapp'),
    'app-yuca': () => import('../shared/yuca')
  },
  data () {
    return {
      chatSelected: ''
    }
  },
  methods: {
    selectChat (chatSelected) {
      // this.chatSelected = chatSelected
      switch (chatSelected) {
        case 'website':
          this.$emit('open-website')
          break
        case 'messenger':
          this.$emit('open-messenger')
          break
        case 'whatsApp':
          this.$emit('open-whatsapp')
          break
      }
    },
    changeSelected (chatSelected) {
      this.chatSelected = chatSelected
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  display: block;
  width: 65px;
  height: 65px;
  overflow: hidden;
  margin-bottom: 10px;
  background-color: #3c3c3c;
  border-radius: 13em;
  list-style: none;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  transition: all 0.3s ease;

  &.desktop-only {
    li {
      transform: translateY(-64px);

      &:nth-child(2) {
        display: none;
      }
    }

    &:hover {
      height: 220px;
      li {
        transform: translateY(15px);
        &:nth-child(2) {
          display: flex;
        }
      }
    }
  }

  li {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;

    transition: all 0.3s ease;
    margin-bottom: 10px;

    &.active {
      display: flex;
    }
    button {
      width: 100%;
      cursor: pointer;

      img {
        height: 40px;
        width: 40px;
      }
    }
  }

  &:hover {
    li {
      transform: translateY(5px);
      display: flex;
    }
  }
}

@media (min-width: 768px) {
  ul {
    width: 35px;
    height: 35px;
  }
  ul.desktop-only {
    li {
      height: 32px;
      transform: translateY(-80px);
    }
    li:nth-child(2) {
      display: flex;
    }

    &:hover {
      height: 125px;
      li {
        transform: translateY(5px);
        &:nth-child(2) {
          display: flex;
        }
      }
    }

    button {
      width: 100%;
      cursor: pointer;

      img {
        height: 22px;
        width: 22px;
      }
    }
  }
}
</style>
