export default {
  namespaced: true,
  state: {
    Wrapper1Visibility: 'ShowWrapper1',
    Wrapper2Visibility: 'HideWrapper2',
    SettedBackgrond: 'bgImgListWrapper',
    nameRoute: '',
    nameVisibilityContent: 'middle'
  },
  getters: {
    currentWrapper1Visibility (state) {
      return state.Wrapper1Visibility
    },
    currentWrapper2Visibility (state) {
      return state.Wrapper2Visibility
    },
    currentSettedBackgrond (state) {
      return state.SettedBackgrond
    },
    currentNameRoute (state) {
      return state.nameRoute
    },
    currentContentVisibility (state) {
      return state.nameVisibilityContent
    }
  },
  mutations: {
    SET_WRAPPER1_VISIBILITY: function (state, visibility) {
      state.Wrapper1Visibility = visibility
    },
    SET_WRAPPER2_VISIBILITY: function (state, visibility) {
      state.Wrapper2Visibility = visibility
    },
    SET_SETTED_BACKGROUND: function (state, visibility) {
      state.SettedBackgrond = visibility
    },
    SET_ROUTE_NAME: function (state, payload) {
      state.nameRoute = payload
    },
    SET_CONTENT_VISIBILITY: function (state, payload) {
      state.nameVisibilityContent = payload
    }
  },
  actions: {
    async setRouteName ({ commit }, data) {
      commit('SET_ROUTE_NAME', data)
    },
    async setContentVisibility ({ commit }, data) {
      commit('SET_CONTENT_VISIBILITY', data)
    }
  }
}
