export default {
  pt: {
    solutions: {
      UContact: {
        Title: '',
        ResumeText:
          'Atendemos os seus Clientes, Fornecedores ou Parceiros de forma profissional com uma estratégia de operação omnicanal e com uma visão 360...',
        TextComplete:
          `No <span style="font-weight: 500">Contact Center</span>, atendemos os seus Clientes, Fornecedores ou Parceiros de forma profissional e eficiente, com uma estratégia de operação omnicanal e com uma visão 360 para uma experiência de cliente única em cada interacção (Vendas, Serviços ao Cliente, Assistência Técnica, Divulgação de Serviços e Soluções ou Tratamento de Processos).
          <br /><br /><br /><div>
            <span class="contact-title">Serviços do Contact Center:</span>
          </div
          `,
        DownContent: `
        <style>
        .contact-title { font-size: 18px; }
        .wrapper2-data-points { padding-top: 10px!important; }
          @media (min-width: 1200px){
            .contact-title { font-size: 24px; }
          } 
        </style>
      <ul>
        <li>ATENDIMENTO TELEFÓNICO
        <p>Inbound | Outbound | Televendas</p></li>

        <li>ATENDIMENTO CANAIS DIGITAIS
        <p>Whatsapp | E-mail | Facebook</p>
        <p>Twitter  | Webchat | Skype </p></li>

        <li>BACKOFFICE
        <p>Tickets | Reclamações | Pedidos</p> </li>
      </ul>`
      },
      UDigital: {
        ResumeText: `Investimos em soluções consistentes, inovadoras e customizadas que permitem elevar a experiência de cliente e potenciem...`,

        TextComplete: `Investimos em soluções consistentes, inovadoras e customizadas que permitem elevar a experiência de cliente e potenciem resultados.
      Estes serviços disruptivos introduzem inteligência no negócio, impulsionados não só pela automação,mas também pela própria transformação digital.`,
        DownContent: `<ul>
        <li>Plataforma de atendimento de Contact Center</li>
        <li>Chatbots</li>
        <li>IVRs (Sistema de atendimento automático)</li>
        <li>Sistemas de pagamento automático</li>
        <li>Business Intelligence</li>
      </ul>`
      },
      UKnow: {
        ResumeText:
          'Através do nosso serviço de consultoria e diagnóstico, mapeamos e entendemos cada negócio retirando insights..',
        TextComplete:
          'Através do nosso serviço de consultoria e diagnóstico, mapeamos e entendemos cada negócio retirando insights e recomendações, com o foco em acções estratégicas para alavancar a experiência e a satisfação do consumidor.',
        DownContent: `
      <ul>
      <li>Consultoria Operacional & Tecnológica</li>
      <li>Formação</li>
      <li>Técnicas de atendimento & gestão de qualidade</li>
      <li>Vendas & Liderança</li>
      <li>Plataforma de E-learning</li>
      <li>Insights e Tendências do Consumidor</li>
      
     </ul>
      `
      },
      UTalent: {
        ResumeText: `Atraímos e capacitamos profissionais com o perfil de competências adequado e alocamos em tempo útil, de acordo com a exigências...`,
        TextComplete: `Atraímos e capacitamos profissionais com o perfil de competências  adequado e alocamos em tempo útil, de acordo com as exigências do seu negócio.
      Acompanhamos estes recursos, através de um modelo de gestão integrada de modo a que os nossos clientes se foquem no seu core business.`,
        DownContent: `<ul>
        <li>Outsourcing de Equipas de Vendas</li>
        <li>Outsourcing de Recursos Especializados</li>
        <li>Plataforma de Outsourcing</li>
      </ul>`
      },
      UMember: {
        ResumeText: `Desenvolvemos modelos de engagement como estratégia de fidelização baseada no fortalecimento das relações entre as empresas...`,
        TextComplete: `Desenvolvemos modelos de engagement como estratégia de fidelização, baseada no fortalecimento das relações entre as empresas e seus clientes, colaboradores e parceiros.`,
        DownContent: `<ul>
        <li>Concierge</li>
        <li>Programas de Fidelização à medida</li>
      </ul>`
      }
    }
  },

  en: {
    solutions: {
      UContact: {
        ResumeText: `We serve your customers, suppliers or partners professionally and efficiently, with an omnichannel operation strategy and a 360º...`,
        TextComplete: `
        In  <span style="font-weight: 500">Contact Center</span>, we serve your customers, suppliers or partners professionally and efficiently, with an omnichannel operation strategy and a 360º vision for a unique customer experience in each interaction (Sales, Customer Services, Technical Assistance, Service Disclosure and Solutions or Process Handling).
        <br /><br /><br /><div>
          <span class="contact-title">Contact Center Services:</span>
        </div
        `,
        DownContent: `
        <style>
          .contact-title { font-size: 18px; }
          .wrapper2-data-points { padding-top: 10px!important; }
            @media (min-width: 1200px){
              .contact-title { font-size: 24px; }
            } 
        </style>
      <ul>
      <li> TELEPHONE SERVICE
      <p>Inbound | Outbound | Telesales</p></li>
      <li>DIGITAL CHANNELS SERVICE
      <p>WhatsApp | Email | Facebook | Twitter | Webchat | Skype</p><br/>
      <li>BACKOFFICE
      <p>Tickets | Complaints | Requests</p></li>
      </ul>`
      },
      UDigital: {
        ResumeText: `We invest in consistent, innovative and customized solutions that allow the elevation of the customer experience and potentiate results... `,
        TextComplete: `We invest in consistent, innovative and customized solutions that allow the elevation of the customer experience and potentiate results. These disruptive services introduce intelligence in business, boosted not only by automation, but also by digital transformation itself.`,
        DownContent: `<ul>
        <li>Contact Center Software Solution</li>
        <li>Chatbots</li>
        <li>IVR (Interactive Voice Response)</li>
        <li>Automatic Payment Systems</li>
        <li>Business Intelligence</li>
      </ul>`
      },
      UKnow: {
        ResumeText: `Through our consulting and diagnostic service, we map and understand each business by removing insights and recommendations, focusing on...`,
        TextComplete: `Through our consulting and diagnostic service, we map and understand each business by removing insights and recommendations, focusing on strategic actions to leverage the consumer experience and satisfaction.`,
        DownContent: ` <ul>
          <li>Operational & Technological Consulting</li>
          <li>Training</li>
          <li>Service Techniques & Quality Management</li>
          <li>Sales & Leadership</li>
          <li>E-learning Platform</li>
          <li>Customer Insights and Trends</li>
        </ul> `
      },
      UTalent: {
        ResumeText: `We attract and train professionals with the appropriate skills profile and allocate them in a timely manner, according to the requirements...`,
        TextComplete: `We attract and train professionals with the appropriate skills profile and allocate them in a timely manner, according to the requirements of your business. We follow these resources through an integrated management model so that our customers can focus on their core businesses.`,
        DownContent: `<ul>
        <li>Outsourcing of Sales Teams</li>
        <li>Outsourcing of Specialized Professionals</li>
        <li>Outsourcing Platform</li>
      </ul>`
      },
      UMember: {
        ResumeText: `We develop engagement models as a loyalty strategy, based on strengthening relationships between companies and their customers...`,
        TextComplete: `We develop engagement models as a loyalty strategy, based on strengthening relationships between companies and their customers, employees and partners.`,
        DownContent: `
      <ul>
      <li>Concierge</li>
      <li>Loyalty Programs Customized</li>
      </ul>
      `
      }
    }
  }
}
