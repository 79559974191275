export default {
  pt: {
    app: {
      readMore: 'Ler mais',
      back: 'Voltar',
      forYourBusiness: 'para o seu negócio',
      menu: {
        about: 'Sobre nós',
        solutions: 'Soluções',
        cases: 'Casos',
        contacts: 'Contactos',
        people: 'Pessoas',
        responsibility: 'Responsabilidade Social',
        heroes: 'Os Nossos Heróis',
        jobs: 'Junte-se a nós'
      },
      fullscreenText: 'Para melhor experiência altere para fullscreen',
      cancelButton: 'Cancelar',
      activateFullScreenButton: 'Activar fullscreen'
    }
  },

  en: {
    app: {
      readMore: 'Read More',
      back: 'Back',
      forYourBusiness: 'for your business',
      menu: {
        about: 'About us',
        solutions: 'Solutions',
        cases: 'Cases',
        contacts: 'Contacts',
        people: 'People',
        responsibility: 'Social Responsibility',
        heroes: 'Our Heroes',
        jobs: 'Join us'
      },
      fullscreenText: 'For best experience change to fullscreen',
      cancelButton: 'Cancel',
      activateFullScreenButton: 'Activate Fullscreen'
    }
  }
}
