export default {
  namespaced: true,
  state: {
    theme: 'ThemeHome',
    buttonTheme: 'btn-menu-light'
  },
  getters: {
    currentTheme (state) {
      return state.theme
    },
    currentButtonTheme (state) {
      return state.buttonTheme
    }
  },
  mutations: {
    SET_MENU_THEME: function (state, theme) {
      state.theme = theme
    },
    SET_BUTTON_THEME: function (state, buttonTheme) {
      state.buttonTheme = buttonTheme
    }
  }
}
