export default {
  pt: {
    modalWhatsApp: {
      Text:
            ` 
            Gostarias de te candidatar a uma vaga na UCALL?
            <br/>
            <br/>
              Requisitos:
            <br/>
            <br/>
            <ul>
              <li>Ensino médio e/ou Licenciatura concluída ou Frequência Universitária</li>
              <br/>
              <li>Capacidade de comunicação escrita e oral, acima da média</li>
              <br/>
              <li>Forte aptidão digital</li>
              <br/>
              <li>Capacidade analítica</li>
              <br/>
              <li>Conhecimentos de Inglês, Francês ou Espanhol serão valorizados</li>
              <br/>
              <li>Perfil dinâmico, criativo e que goste de aprender</li>
              <br/>
            </ul>
            Se tens o perfil, envia-nos um Vídeo via WhatsApp para (+244) 923 190 190.
            <br/>
            <br/>
            Esperamos por ti!
        `
    }
  },

  en: {
    modalWhatsApp: {
      Text:
          ` 
          Would you like to apply for an Opportunity at UCALL?
          <br/>
          <br/>
          Requirements:
          <br/>
          <br/>
          <ul>
            <li>High School and/or Bachelor's Degree completed or University Attendance</li>
            <br/>
            <li>High level of written and oral communication skills</li>
            <br/>
            <li>Strong digital skills</li>
            <br/>
            <li>Analytical skills</li>
            <br/>
            <li>Knowledge of English, French or Spanish will be valued</li>
            <br/>
            <li>Dynamic profile, creative and who likes to learn!</li>
            <br/>
          </ul>
          <br/>
          If you have the profile, send us a video by WhatsApp to (+244) 923 190 190. 
          <br/>
          <br/>
          We are waiting for you!
      `
    }
  }
}
