import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '@/i18n'

/**
 * @author Acidiney Dias <acidiney.dias@digitalfactory.co.ao>
 * @description Internacionalization dependency
 */

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'pt',
  fallbackLocale: 'pt',
  messages
})

export { i18n }
